import React, {
	type ComponentProps,
	type ComponentType,
	lazy,
	useContext,
	useMemo,
	useState,
} from 'react';
import { fireErrorAnalytics } from '@atlassian/error-handling';
import { getConfig, MODE } from '../../config';
import { COLLECTED, PHASE } from '../../constants';
import { WaitContext } from '../../lazy-wait';
import { LazyPhaseContext } from '../../phase';
import { ProfilerContext } from '../../profiler';
import { LazySuspenseContext } from '../../suspense';
import { type Deferred } from '../deferred';
import { createLoaderError } from '../errors';
import { PlaceholderFallbackHydrate } from '../placeholders/hydrate';
import { PlaceholderFallbackRender } from '../placeholders/render';

import type { Status } from './types';
import { useSubscription } from './utils';

const reportedDuplicate: Set<string> = new Set();

export function createComponentClient<C extends ComponentType<any>>({
	defer,
	deferred,
	dataLazyId,
	moduleId,
}: {
	defer: number;
	deferred: Deferred<C>;
	dataLazyId: string;
	moduleId: string;
}) {
	const ResolvedLazy = lazy(() => deferred.promise);

	return (props: ComponentProps<C>) => {
		// use a single piece of state to hold info about progress or eventually
		// throw an error. We do change it via direct mutation as re-renders
		// break Suspense in React 18, making it lose hydration state
		const [status, bubbleError] = useState<Status>(() => ({
			noWait: undefined,
			phase: defer === PHASE.AFTER_PAINT ? false : true,
			preloaded: defer === PHASE.AFTER_PAINT ? false : true,
			started: false,
		}));

		const { usePostTaskPhases, trackDuplicateLazyIds } = getConfig();
		const { setFallback, getIsBoundaryInitialized } = useContext(LazySuspenseContext);
		const profiler = useContext(ProfilerContext).current;

		const load = () => {
			if (usePostTaskPhases) {
				if (status.started) {
					return;
				}
			} else {
				if (status.started || !status.phase || !status.noWait) {
					return;
				}
			}

			status.started = true;
			let onResolve;
			if (profiler) {
				const eventInfo = { identifier: moduleId };
				onResolve = () => {
					profiler.onLoadComplete(eventInfo);
				};
				profiler.onLoadStart(eventInfo);
			}

			const result = deferred.start().catch((err: Error) => {
				// Throw the error within the component lifecycle
				// refer to https://github.com/facebook/react/issues/11409
				bubbleError(() => {
					throw createLoaderError(err);
				});
			});

			if (onResolve) {
				result.then(onResolve);
			}
		};

		if (usePostTaskPhases) {
			// Start loading immediately, as the loader itself will be delayed until the appropriate phase
			useMemo(() => {
				load();
				// eslint-disable-next-line react-hooks/exhaustive-deps
			}, []);
		} else {
			// Subscribe to LazyWait context, triggering load when until is true
			useSubscription({
				context: WaitContext,
				load,
				onValue: (v) => (status.noWait = v === 1),
			});

			if (defer === PHASE.AFTER_PAINT) {
				// Subscribe to LazyPhase context, triggering load when own phase starts
				useSubscription({
					context: LazyPhaseContext,
					load,
					onValue: (v) => (status.phase = v >= defer),
				});
			}
		}

		useMemo(() => {
			// Skip collecting fallbacks if the boundary is already initialized
			if (usePostTaskPhases && getIsBoundaryInitialized()) {
				return;
			}

			const contentLength = COLLECTED.get(dataLazyId)?.length || 0;
			// find SSR content (or fallbacks) wrapped in inputs based on lazyId
			const content = (COLLECTED.get(dataLazyId) || []).shift();

			if (!content) {
				return;
			}

			if (trackDuplicateLazyIds && contentLength > 1 && !reportedDuplicate.has(dataLazyId)) {
				// This means there are multiple components for the same lazyId
				// This can cause performance issues, so we should log it and resolve it later on
				reportedDuplicate.add(dataLazyId);
				fireErrorAnalytics({
					error: new Error(
						`Duplicate components for ${dataLazyId} found, module name is ${moduleId}, total count: ${reportedDuplicate.size}`,
					),
					meta: {
						id: 'rll-duplicate-error',
						packageName: 'reactLooselyLazy',
						teamName: 'jfp-magma',
					},
					attributes: {
						moduleName: moduleId,
					},
				});
			}

			// override Suspense fallback with magic input wrappers
			const component =
				getConfig().mode === MODE.RENDER ? (
					<PlaceholderFallbackRender id={dataLazyId} content={content} />
				) : (
					<PlaceholderFallbackHydrate id={dataLazyId} content={content} />
				);
			setFallback(component);
		}, [setFallback, getIsBoundaryInitialized, usePostTaskPhases, trackDuplicateLazyIds]);

		useMemo(() => {
			const { mode, react18 } = getConfig();

			// we dont remove fallback in RENDER mode as it happens too early
			// however it's not a problem for HYDRATION as Suspense will handle all nuances
			if (mode === MODE.HYDRATE && react18) {
				// Allow hydration to support partials without server components
				// suspense will discard ssr during hydration if re-renders so we
				// set a dummy fallback to block updates from the provider until we resolve
				setFallback(<></>);
			}
		}, [setFallback]);

		return <ResolvedLazy {...props} />;
	};
}
